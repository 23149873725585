@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
body {
  margin: 0;
  font-family:'Space Mono', monospace;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home .container .img{
position:absolute;
top: 20px;
left: -100px;
opacity: 0.6;
}

